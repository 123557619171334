import React from 'react';

export const IconLocation = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0,0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M15.049,15h2.509c0.861,0 1.625,0.551 1.897,1.368l0.667,2c0.432,1.295 -0.532,2.632 -1.897,2.632h-12.45c-1.365,0 -2.329,-1.337 -1.897,-2.632l0.667,-2c0.271,-0.817 1.036,-1.368 1.897,-1.368h2.51"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M12,18c0,0 -6,-4.792 -6,-9.167c0,-3.221 2.686,-5.833 6,-5.833c3.314,0 6,2.612 6,5.833c0,4.375 -6,9.167 -6,9.167Z"
        ></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M13.4142,7.58579c0.781049,0.781049 0.781049,2.04738 0,2.82843c-0.781049,0.781049 -2.04738,0.781049 -2.82843,0c-0.781049,-0.781049 -0.781049,-2.04738 0,-2.82843c0.781049,-0.781049 2.04738,-0.781049 2.82843,-8.88178e-16"
        ></path>
      </g>
    </svg>
  );
};
