import React, {useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import {HeadingS} from '../../Typography';
import {Actions} from './Actions';
import {Input, Checkbox} from '../../FinalForm';
import {IconPhone} from '../../Icons/IconPhone';
import {IconEnvelope} from '../../Icons/IconEmail';
import {IconLocation} from '../../Icons/IconLocation';

export function Block(props: {
  block: BusinessCardBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  return (
    <Container ref={ref}>
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockContent>
        <HeadingS>{block.title}</HeadingS>
        {block.email && (
          <Item smallMargin>
            <a href={`mailto:${block.email}`}>
              <IconEnvelopeStyled /> {block.email}
            </a>
          </Item>
        )}
        {block.phone && (
          <Item>
            <IconPhoneStyled /> {block.phone}
          </Item>
        )}
        {block.address && (
          <>
            <Item smallMargin>
              <IconLocationStyled /> {block.address}
            </Item>
            {block.showMap && (
              <Map>
                <iframe
                  title={block.address}
                  src={`https://maps.google.com/maps?hl=it&q=${block.address}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                />
              </Map>
            )}
          </>
        )}
      </BlockContent>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 1,
  h: 2,
};

export const blockValues: BusinessCardBlockWidget = {
  type: 'businessCard',
  title: 'Digital Space',
  email: 'esempio@email.it',
  phone: '06 123455843',
  address: 'Indirizzo, numero civico, CAP - Città',
  showMap: true,
};

export function EditForm(props: {block: BusinessCardBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Nome azienda" />
      <Input name="email" type="text" label="Indirizzo e-mail" />
      <Input name="phone" type="text" label="Numero di telefono" />
      <Input name="address" type="text" label="Indirizzo" />
      <Checkbox name="showMap" id="showMap">
        Mostra la mappa
      </Checkbox>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  position: relative;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  height: 100%;

  @media ${(props) => props.theme.responsive.laptop} {
    height: auto;
    min-height: 100%;
  }
`;

const BlockContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  z-index: 2;
  height: 100%;
  overflow: hidden;

  h3 {
    margin-bottom: 16px;
    line-height: 1.2;
    color: ${(props) => props.theme.colors.main};
  }

  div {
    font-family: ${(props) => props.theme.typography.main};
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.bodyText};

    p {
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
    }

    a {
      color: ${(props) => props.theme.colors.main};
      display: flex;
      align-items: center;
      text-decoration: none;
    }

    ul,
    ol {
      padding-left: 15px;
    }
  }
`;

const Map = styled.div`
  width: 100%;
  position: relative;
  flex: 1;

  iframe {
    position: absolute;
    border: none;
    left: -24px;
    right: 0;
    top: 0;
    bottom: -24px;
    width: calc(100% + 48px);
    height: calc(100% + 24px);
  }
`;

const IconPhoneStyled = styled(IconPhone).attrs((props) => ({
  fill: props.theme.colors.main,
  size: '26px',
}))``;
const IconEnvelopeStyled = styled(IconEnvelope).attrs((props) => ({
  fill: props.theme.colors.main,
  size: '26px',
}))``;
const IconLocationStyled = styled(IconLocation).attrs((props) => ({
  fill: props.theme.colors.main,
  size: '26px',
}))``;

const Item = styled.div<{smallMargin?: boolean}>`
  display: flex;
  align-items: center;
  margin: 0 0 16px;

  svg {
    margin-right: 6px;
    flex: 0 0 26px;
  }

  ${(props) =>
    props.smallMargin &&
    css`
      margin-bottom: 6px;
    `}
`;
