import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {HeadingS} from '../../Typography';
import {Actions} from './Actions';
import defaultImg from './default.png';
import {Input, ImageUploader} from '../../FinalForm';

export function Block(props: {
  block: ImageBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  const img = block.image || defaultImg.src;
  const handleClick = () => {
    if (block.link && !edit && !remove) {
      window.open(block.link, '_blank');
    }
    return;
  };
  return (
    <Container
      style={{backgroundImage: `url('${img}')`}}
      onClick={() => handleClick()}
      $hasLink={block.link?.length > 0}
      $hasShadow={block.title?.length > 0}
      ref={ref}
    >
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockContent>
        <HeadingS>{block.title}</HeadingS>
      </BlockContent>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 1,
  h: 1,
};

export const blockValues: ImageBlockWidget = {
  type: 'image',
  title: 'Immagine',
};

export function EditForm(props: {block: ImageBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Titolo" />
      <ImageUploader
        name="image"
        label="Immagine"
        previewLabel="Preview"
        help="Larghezza massima immagine 2000px"
        defaultImage={props.block.image || defaultImg.src}
      />
      <Input name="link" type="url" label="Link da aprire al click" />
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;

  ${(props) =>
    props.$hasShadow &&
    `
      &:after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(5, 2, 2, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        opacity: 0.7;
        z-index: 1;
      }
    `}

  ${(props) =>
    props.$hasLink &&
    `
    cursor: pointer;
    `}
`;

const BlockContent = styled.div`
  display: block;
  padding: 24px;
  position: relative;
  z-index: 2;

  h3 {
    margin-bottom: 0;
    color: #fff;
    line-height: 1.2;
  }
`;
