import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {HeadingS} from '../../Typography';
import {Actions} from './Actions';
import {Input, TextEditor} from '../../FinalForm';

export function Block(props: {
  block: TextBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  return (
    <Container ref={ref}>
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockContent>
        <HeadingS>{block.title}</HeadingS>
        <div dangerouslySetInnerHTML={{__html: block.text}}></div>
      </BlockContent>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 1,
  h: 1,
};

export const blockValues: TextBlockWidget = {
  type: 'text',
  title: 'Testo',
  text: 'Mostra un titolo e del testo',
};

export function EditForm(props: {block: TextBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Titolo" />
      <TextEditor name="text" label="Testo" />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  position: relative;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding-bottom: 12px;

  @media ${(props) => props.theme.responsive.laptop} {
    height: auto;
    min-height: 100%;
  }
`;

const BlockContent = styled.div`
  display: block;
  padding: 24px;
  position: relative;
  z-index: 2;
  height: 100%;
  overflow: hidden;

  h3 {
    margin-bottom: 5px;
    line-height: 1.2;
    color: ${(props) => props.theme.colors.main};
  }

  div {
    font-family: ${(props) => props.theme.typography.main};
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.bodyText};
    margin: 0 0 16px;

    p {
      font-size: 14px;
      line-height: 20px;
      margin-top: 0;
    }

    a {
      color: ${(props) => props.theme.colors.main};
    }

    ul,
    ol {
      padding-left: 15px;
    }
  }
`;
