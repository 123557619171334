import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {HeadingS, TextS} from '../../Typography';
import {Actions} from './Actions';
import defaultImg from './default.png';
import {Input, ImageUploader} from '../../FinalForm';

export function Block(props: {
  block: TileBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  const img = block.image || defaultImg.src;
  const handleClick = () => {
    if (block.link && !edit && !remove) {
      window.open(block.link, '_blank');
    }
    return;
  };
  return (
    <Container
      onClick={() => handleClick()}
      $hasLink={!!block.link?.length}
      ref={ref}
    >
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockImage style={{backgroundImage: `url('${img}')`}} />
      <BlockContent>
        <HeadingS>{block.title}</HeadingS>
        <TextS>{block.text}</TextS>
      </BlockContent>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 2,
  h: 2,
  minH: 2,
};

export const blockValues: TileBlockWidget = {
  type: 'tile',
  title: 'Testo e immagine',
  text: 'Mostra un titolo e del testo',
};

export function EditForm(props: {block: TileBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Titolo" required />
      <Input name="text" type="text" label="Testo" />
      <ImageUploader
        name="image"
        label="Immagine"
        previewLabel="Preview"
        help="Larghezza massima immagine 2000px"
        defaultImage={props.block.image || defaultImg.src}
      />
      <Input name="link" type="url" label="Link da aprire al click" />
    </>
  );
}

const Container = styled.div<{$hasLink: boolean}>`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${(props) => props.theme.responsive.desktopSmall} {
    height: auto;
  }

  ${(props) =>
    props.$hasLink &&
    `
    cursor: pointer;
    `}
`;

const BlockImage = styled.div`
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100px;
`;

const BlockContent = styled.div`
  display: block;
  padding: 12px 24px;
  position: absolute;
  width: 100%;
  z-index: 2;
  background-color: #fff;

  h3 {
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.main};
    line-height: 1.2;
  }
  p {
    margin-bottom: 0;
  }
`;
