import {DependencyList, Reducer, useEffect, useReducer} from 'react';

type Fetcher<T> = () => Promise<T>;

type FetcherState<T> =
  | {loading: true; result: null; error?: null; errorMessage?: null}
  | {loading: false; result: T; error: null; errorMessage: null}
  | {loading: false; result: null; error: Error; errorMessage: any};

type Action<T> =
  | {type: 'success'; result: T}
  | {type: 'error'; error: Error | null; errorMessage?: any};

function dataReducer<T>(
  state: FetcherState<T>,
  action: Action<T>
): FetcherState<T> {
  switch (action.type) {
    case 'success':
      return {
        loading: false,
        result: action.result,
        error: null,
        errorMessage: null,
      };

    case 'error':
      return {
        loading: false,
        result: null,
        error: action.error,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
}

type AxiosReducer<T> = Reducer<FetcherState<T>, Action<T>>;

export default function useAxios<T>(
  fetcher: Fetcher<T>,
  deps: DependencyList = []
): FetcherState<T> {
  const [state, dispatch] = useReducer<AxiosReducer<T>>(dataReducer, {
    loading: true,
    result: null,
    error: null,
    errorMessage: null,
  });

  useEffect(() => {
    (async function () {
      try {
        const result = await fetcher();

        dispatch({
          type: 'success',
          result,
        });
      } catch (e) {
        dispatch({
          type: 'error',
          error: e,
          errorMessage: e?.response?.data || e?.message,
        });
      }
    })();
  }, deps);

  return state;
}
