import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {IconEdit, IconDelete} from '../../../components/Icons';

export function Actions(props: {edit: Function; remove: Function; id: string}) {
  const {edit, remove, id} = props;
  const theme = useContext(ThemeContext);

  return (
    <ActionsContainer>
      {edit && (
        <button type="button" onClick={() => edit(id)} title="Modifica">
          <IconEdit fill="#000" size="20px" />
        </button>
      )}
      {remove && (
        <button type="button" onClick={() => remove(id)} title="Elimina">
          <IconDelete fill={theme.colors.decline} size="20px" />
        </button>
      )}
    </ActionsContainer>
  );
}

const ActionsContainer = styled.div`
  position: absolute;
  z-index: 3;
  right: 12px;
  top: 12px;

  button {
    margin-right: 12px;
    appearance: none;
    border: none;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 10px ${(props) => props.theme.colors.shadow};

    &:last-child {
      margin-right: 0;
    }
  }
`;
