import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {HeadingM, TextS} from '../../Typography';
import {Actions} from './Actions';
import defaultImg from './default.png';
import {Input, ImageUploader} from '../../FinalForm';

export function Block(props: {
  block: HeroBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  const img = block.image || defaultImg.src;
  const handleClick = () => {
    if (block.link && !edit && !remove) {
      window.open(block.link, '_blank');
    }
    return;
  };
  return (
    <Container
      style={{backgroundImage: `url('${img}')`}}
      onClick={() => handleClick()}
      $hasLink={block.link?.length > 0}
      $hasShadow={block.title?.length > 0 || block.text?.length > 0}
      ref={ref}
    >
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockContent>
        <HeadingM>{block.title}</HeadingM>
        <TextS>{block.text}</TextS>
      </BlockContent>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 3,
  h: 1,
  minW: 3,
};

export const blockValues: HeroBlockWidget = {
  type: 'hero',
  title: 'In Primo piano',
  text: 'Titolo e sottotitolo su immagine',
};

export function EditForm(props: {block: HeroBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Titolo" />
      <Input name="text" type="text" label="Testo" />
      <ImageUploader
        name="image"
        label="Immagine"
        previewLabel="Preview"
        help="Larghezza massima immagine 2000px"
        defaultImage={props.block.image || defaultImg.src}
      />
      <Input name="link" type="url" label="Link da aprire al click" />
    </>
  );
}

const Container = styled.div<{$hasShadow?: boolean; $hasLink?: boolean}>`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  flex: 0 0 100%;

  ${(props) =>
    props.$hasShadow &&
    `
      &:after {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(5, 2, 2, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        opacity: 0.8;
        z-index: 1;
      }
  `};

  ${(props) =>
    props.$hasLink &&
    `
    cursor: pointer;
    `}
`;

const BlockContent = styled.div`
  display: block;
  padding: 24px;
  position: relative;
  z-index: 2;
  width: 100%;

  h2 {
    margin-bottom: 0;
    color: #fff;
  }

  p {
    color: #fff;
    margin-bottom: 0;
  }
`;
