import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Actions} from './Actions';
import {Input, ImageUploader} from '../../FinalForm';
import ReactPlayer from 'react-player';
import PlayIcon from '../../../assets/images/icons/icon-play.svg';

export function Block(props: {
  block: VideoBlockWidget;
  edit: Function;
  remove: Function;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !props.edit) {
      return;
    }

    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref, props.edit]);

  const {block, edit, remove} = props;
  const img = block.image;
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setShowVideo(true);
  }, []);

  return (
    <Container ref={ref}>
      {(edit || remove) && (
        <Actions edit={edit} remove={remove} id={block.id} />
      )}
      <BlockVideoContainer>
        {showVideo && (
          <ReactPlayer
            light={img || true}
            width="100%"
            height="100%"
            controls={true}
            playing={true}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  modestbranding: 1,
                  rel: 0,
                },
              },
            }}
            url={block.url || 'https://www.youtube.com/watch?v=W-a6fyRiXQo'}
          />
        )}
      </BlockVideoContainer>
    </Container>
  );
}

export const blockLayout: LayoutBlock = {
  w: 2,
  h: 2,
};

export const blockValues: VideoBlockWidget = {
  type: 'video',
  title: 'Video',
  url: 'https://www.youtube.com/watch?v=W-a6fyRiXQo',
};

export function EditForm(props: {block: VideoBlockWidget}) {
  return (
    <>
      <Input name="title" type="text" label="Titolo" required />
      <ImageUploader
        name="image"
        label="Immagine personalizzata per il video"
        previewLabel="Preview"
        help="Larghezza massima immagine 2000px"
        defaultImage={props.block.image}
      />
      <Input
        name="url"
        type="url"
        label="Link del video da embeddare"
        help="Per i video di Facebook è necessario caricare un'immagine personalizzata"
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  box-shadow: 0px 10px 20px ${(props) => props.theme.colors.shadow};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${(props) => props.theme.responsive.desktopSmall} {
    height: auto;
  }
`;

const BlockVideoContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 125px;

  > div:first-child {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .react-player__play-icon {
    border: none !important;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${PlayIcon.src});
  }
`;
