import React from 'react';

import * as BlockHero from './BlockHero';
import * as BlockText from './BlockText';
import * as BlockImage from './BlockImage';
import * as BlockTile from './BlockTile';
import * as BlockVideo from './BlockVideo';
import * as BlockBusinessCard from './BlockBusinessCard';

interface BlockProps {
  block: AllBlockWidget;
  edit?: Function;
  remove?: Function;
}

export default function Block(props: BlockProps) {
  if (props.block.type === 'hero') {
    return (
      <BlockHero.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  if (props.block.type === 'text') {
    return (
      <BlockText.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  if (props.block.type === 'image') {
    return (
      <BlockImage.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  if (props.block.type === 'tile') {
    return (
      <BlockTile.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  if (props.block.type === 'video') {
    return (
      <BlockVideo.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  if (props.block.type === 'businessCard') {
    return (
      <BlockBusinessCard.Block
        block={props.block}
        edit={props.edit}
        remove={props.remove}
      />
    );
  }

  throw new Error('Unknown block type');
}

export function FormBlock(props: BlockProps) {
  if (props.block.type === 'hero') {
    return <BlockHero.EditForm block={props.block} />;
  }

  if (props.block.type === 'text') {
    return <BlockText.EditForm block={props.block} />;
  }

  if (props.block.type === 'image') {
    return <BlockImage.EditForm block={props.block} />;
  }

  if (props.block.type === 'tile') {
    return <BlockTile.EditForm block={props.block} />;
  }

  if (props.block.type === 'video') {
    return <BlockVideo.EditForm block={props.block} />;
  }

  if (props.block.type === 'businessCard') {
    return <BlockBusinessCard.EditForm block={props.block} />;
  }

  throw new Error('Unknown block type');
}

export function blockGridLayout(type: BlockType): LayoutBlock {
  if (type === 'hero') {
    return BlockHero.blockLayout;
  }

  if (type === 'text') {
    return BlockText.blockLayout;
  }

  if (type === 'image') {
    return BlockImage.blockLayout;
  }

  if (type === 'tile') {
    return BlockTile.blockLayout;
  }

  if (type === 'video') {
    return BlockVideo.blockLayout;
  }

  if (type === 'businessCard') {
    return BlockBusinessCard.blockLayout;
  }

  throw new Error('Unknown block type');
}

export function blockDefaultValues(type: BlockType): AllBlockWidget {
  if (type === 'hero') {
    return BlockHero.blockValues;
  }

  if (type === 'text') {
    return BlockText.blockValues;
  }

  if (type === 'image') {
    return BlockImage.blockValues;
  }

  if (type === 'tile') {
    return BlockTile.blockValues;
  }

  if (type === 'video') {
    return BlockVideo.blockValues;
  }

  if (type === 'businessCard') {
    return BlockBusinessCard.blockValues;
  }

  throw new Error('Unknown block type');
}
